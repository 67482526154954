import Button from "components/basic/button";
import Flex from "components/basic/flex";
import Heading from "components/basic/heading";
import Icon from "components/basic/icon";
import Image from "components/basic/image";
import { P, Span } from "components/basic/text";
import Constants from "constant";
import AccordionSection from "./accordion";
import { Input } from "components/basic/input";
import Grid from "components/basic/grid";
import Footer from "components/pages/footer";
import Header from "components/pages/header/landing";
import styled, { keyframes } from "styled-components";

interface LandingPageUIProps {
    handleEmail: (e: any) => void
    handleSubmit: (e: any) => void
}

export default function LandingPageUI({ handleEmail, handleSubmit }: LandingPageUIProps) {

    return (
        <Flex $style={{
            fDirection: "column",
            overflow: "hidden"
        }}>
            <Header />
            <Community>
                <Icon icon={'community'} width="2rem" />
            </Community>
            <Flex id="home" as="section" $style={{
                w: "100%",
                minH: "calc(100vh - 12rem)",
                mb: "8rem",
                vAlign: "center",
                hAlign: "center",
                position: 'relative'
            }}>
                <Flex $style={{
                    position: "absolute",
                    top: "-300px",
                    left: "-200px",
                    zIndex: "-1"
                }}>
                    <Image src={Constants.Imgs.GRADIENT_1} />
                </Flex>
                <Flex $style={{
                    position: "absolute",
                    bottom: "-300px",
                    right: "-400px",
                    zIndex: "-1"
                }}>
                    <Image src={Constants.Imgs.GRADIENT_2} />
                </Flex>
                <Flex $style={{
                    maxW: "1440px",
                    w: "100%",
                    vAlign: "center",
                    queries: {
                        680: {
                            fDirection: "column"
                        }
                    }
                }}>
                    <Flex data-aos="fade-up" $style={{
                        fDirection: "column",
                        gap: "2rem",
                        w: "100%",
                        maxW: "1440px",
                        m: "auto",
                        p: "1rem",
                        queries: {
                            680: {
                                order: "2"
                            }
                        }
                    }}>
                        <Flex $style={{
                            fDirection: "column",
                            gap: "2rem",
                            maxW: "38rem"
                        }}>
                            <Heading level={1}>
                                The Unification Layer
                            </Heading>
                            <P>
                                Dedicated chains, unified experience.
                            </P>
                        </Flex>
                        <Flex $style={{
                            gap: "1rem"
                        }}>
                            <Button $style={{
                                bg: "bg-gradient",
                                kind: "radius",
                                hover: "linear-gradient(to right, #1d60ac, #791b7a)"
                            }}>
                                Get Started
                            </Button>
                            <a href="https://docs.fractalprotocol.io/architecture/overview" target="_blank" rel="noreferrer">
                                <Button $style={{
                                    bg: "bg-gradient",
                                    kind: "radius",
                                    hover: "linear-gradient(to right, #1d60ac, #791b7a)"
                                }}>
                                    Learn More
                                </Button>
                            </a>
                        </Flex>
                    </Flex>
                    <Flex data-aos="fade-left" $style={{
                        maxW: "45rem",
                        w: "100%",
                        p: "1rem",
                        queries: {
                            680: {
                                order: "1"
                            }
                        }
                    }}>
                        <Image src={Constants.Imgs.BLOCKCHAIN3} />
                    </Flex>
                </Flex>
            </Flex>
            <Flex data-aos="fade-up" id="subscribe" as="section" $style={{
                fDirection: "column",
                w: "100%",
                maxW: "1040px",
                m: "auto",
                mb: "12rem",
                gap: "2rem",
                p: "1rem"
            }}>
                <Flex $style={{
                    fDirection: "column",
                    vAlign: "center"
                }}>
                    <Heading level={2}>Join the mission</Heading>
                    <P>
                        Stay informed with updates on our test network, and discover exclusive opportunities to get involved.
                    </P>
                </Flex>
                <Flex $style={{
                    gap: "1rem"
                }}>
                    <Input onChange={(e: any) => handleEmail(e)} placeholder="Enter your email" />
                    <Button onClick={handleSubmit} $style={{
                        bg: "bg-gradient",
                        kind: "radius",
                        hover: "linear-gradient(to right, #1d60ac, #791b7a)"
                    }}>
                        Subscribe
                    </Button>
                </Flex>
            </Flex>
            <Flex $style={{
                hAlign: "center",
                position: "relative"
            }}>

                <Flex data-aos="fade-up" id="about" as="section" $style={{
                    fDirection: "column",
                    w: "100%",
                    maxW: "1440px",
                    m: "auto",
                    mb: "12rem",
                    gap: "5rem",
                    p: "1rem"
                }}>
                    <Flex $style={{
                        fDirection: "column",
                        vAlign: "center",
                    }}>
                        <Heading level={2}>Empowering Your Rollup Experience</Heading>
                        <P>Experience the power and simplicity of Fractal's innovative solution, tailored to elevate your rollup journey.</P>
                    </Flex>
                    <Grid $style={{
                        columns: "4",
                        gap: "2rem",
                        $queries: {
                            1024: {
                                columns: "2"
                            },
                            480: {
                                columns: "1"
                            }
                        }
                    }}>
                        <Flex $style={{
                            p: "1px",
                            radius: "1rem",
                        }}>
                            <Flex $style={{
                                background: "var(--bg-sub)",
                                radius: "1rem",
                                p: "2rem",
                                fDirection: "column",
                                gap: ".5rem"
                            }}>
                                <Flex $style={{
                                    fDirection: "column",
                                    vAlign: "flex-start",
                                    gap: "1rem"
                                }}>
                                    <Icon icon={'global-network'} />
                                    <Heading level={4}>Shared Validity Sequencers</Heading>
                                </Flex>
                                <P>
                                    Fractal offers a decentralized network of shared validity sequencers, empowering rollups to manage transaction ordering, block production, and enable cross-rollup atomic composability.
                                </P>
                            </Flex>
                        </Flex>
                        <Flex $style={{
                            p: "1px",
                            radius: "1rem",
                        }}>
                            <Flex $style={{
                                background: "var(--bg-sub)",
                                radius: "1rem",
                                p: "2rem",
                                fDirection: "column",
                                gap: ".5rem"
                            }}>
                                <Flex $style={{
                                    fDirection: "column",
                                    vAlign: "flex-start",
                                    gap: "1rem"
                                }}>
                                    <Icon icon={'gpu'} />
                                    <Heading level={4}>Shared Provers</Heading>
                                </Flex>
                                <P>
                                    Access the vast computational power of Fractal's decentralised shared provers that generate zero-knowledge proofs for all rollups on the platform.
                                </P>
                            </Flex>
                        </Flex>
                        <Flex $style={{
                            p: "1px",
                            radius: "1rem",
                        }}>
                            <Flex $style={{
                                background: "var(--bg-sub)",
                                radius: "1rem",
                                p: "2rem",
                                fDirection: "column",
                                gap: ".5rem"
                            }}>
                                <Flex $style={{
                                    fDirection: "column",
                                    vAlign: "flex-start",
                                    gap: "1rem"
                                }}>
                                    <Icon icon={'click'} />
                                    <Heading level={4}>One-Click Deployment</Heading>
                                </Flex>
                                <P>
                                    Deploying a rollup has never been easier. With just a single click, Fractal diminishes barriers, making rollup deployment a breeze.
                                </P>
                            </Flex>
                        </Flex>
                        <Flex $style={{
                            p: "1px",
                            radius: "1rem",
                        }}>
                            <Flex $style={{
                                background: "var(--bg-sub)",
                                radius: "1rem",
                                p: "2rem",
                                fDirection: "column",
                                gap: ".5rem"
                            }}>
                                <Flex $style={{
                                    fDirection: "column",
                                    vAlign: "flex-start",
                                    gap: "1rem"
                                }}>
                                    <Icon icon={'padlock'} />
                                    <Heading level={4}>Uncompromising Security</Heading>
                                </Flex>
                                <P>
                                    Fractal employs state-of-the-art zero-knowledge proof technology enabling rollups on the hub to inherit Ethereum's industry leading security.
                                </P>
                            </Flex>
                        </Flex>
                    </Grid>
                </Flex>
            </Flex>
            <Flex $style={{
                hAlign: "center",
                position: "relative"
            }}>
                <Flex $style={{
                    position: "absolute",
                    right: "-400px",
                    top: "-300px"
                }}>
                    <Image src={Constants.Imgs.GRADIENT_4} />
                </Flex>
                <Flex data-aos="fade-up" as="section" $style={{
                    w: "100%",
                    maxW: "1440px",
                    m: "auto",
                    vAlign: "center",
                    gap: "2rem",
                    mb: "12rem",
                    hAlign: "space-between",
                    p: "1rem",
                    queries: {
                        680: {
                            fDirection: "column"
                        }
                    }
                }}>
                    <Flex $style={{
                        maxW: "45rem",
                    }}>
                        <Image src={Constants.Imgs.BLOCKCHAIN1} />
                    </Flex>
                    <Flex $style={{
                        fDirection: "column",
                        maxW: "30rem",
                        gap: "3rem"
                    }}>
                        <Flex $style={{
                            fDirection: "column",
                            gap: "1rem"
                        }}>
                            <Heading level={2}>Decentralised by Design</Heading>
                            <P>
                                Our technological architecture places decentralization at its forefront. Built from the ground up with this foundational principle, Fractal's shared validity sequencer platform ensures every transaction not only benefits from scalability and atomic interoperability but also upholds the unwavering integrity of a truly decentralized system.
                            </P>
                        </Flex>
                        <Flex>
                            <Button $style={{
                                bg: "bg-gradient",
                                kind: "radius",
                                hover: "linear-gradient(to right, #1d60ac, #791b7a)"
                            }}>
                                GET STARTED
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex $style={{
                position: "relative"
            }}>
                <Flex $style={{
                    position: "absolute",
                    top: "-400px",
                    left: "-35%",
                    queries: {
                        620: {
                            top: "100px"
                        }
                    }
                }}>
                    <Image src={Constants.Imgs.LOOPER} />
                </Flex>
                <Flex data-aos="fade-up" id="docs" as="section" $style={{
                    w: "100%",
                    maxW: "1440px",
                    m: "auto",
                    vAlign: "center",
                    gap: "2rem",
                    hAlign: "space-between",
                    mb: "12rem",
                    p: "1rem",
                    queries: {
                        680: {
                            fDirection: "column"
                        }
                    }
                }}>
                    <Flex $style={{
                        fDirection: "column",
                        maxW: "33rem",
                        gap: "2rem",
                        queries: {
                            680: {
                                order: "2"
                            }
                        }
                    }}>
                        <Heading level={2}>Rollup Technology: Paving the Way to Mass Adoption</Heading>
                        <P>
                            Blockchain technology has brought significant advancements tovarious industries.
                        </P>
                        <Flex $style={{
                            fDirection: "column",
                            gap: "1.5rem"
                        }}>
                            <Flex $style={{
                                vAlign: "flex-start"
                            }}>
                                <Flex $style={{
                                    p: "1rem"
                                }}>
                                    <Icon icon={'speed'} />
                                </Flex>
                                <Flex $style={{
                                    fDirection: "column",
                                    gap: ".5rem"
                                }}>
                                    <Heading level={4}>Scalability Unleashed</Heading>
                                    <P> Rollup technology significantly increases transaction throughput, making blockchain platforms scalable enough to handle the demands of mass adoption.</P>
                                </Flex>
                            </Flex>
                            <Flex $style={{
                                vAlign: "flex-start"
                            }}>
                                <Flex $style={{
                                    p: "1rem"
                                }}>
                                    <Icon icon={'storage'} />
                                </Flex>
                                <Flex $style={{
                                    fDirection: "column",
                                    gap: ".5rem"
                                }}>
                                    <Heading level={4}>Effortless Rollup Deployment</Heading>
                                    <P>Effortless rollup deployment establishes the essential groundwork for the widespread adoption of rollups. This ease of deployment empowers developers, paving the way for an exponential rise in decentralized app chains across the blockchain ecosystem.</P>
                                </Flex>
                            </Flex>
                            <Flex $style={{
                                vAlign: "flex-start"
                            }}>
                                <Flex $style={{
                                    p: "1rem"
                                }}>
                                    <Icon icon={'bank'} />
                                </Flex>
                                <Flex $style={{
                                    fDirection: "column",
                                    gap: ".5rem"
                                }}>
                                    <Heading level={4}>Cost Efficiency</Heading>
                                    <P>Rollups optimize transaction processing, leading to lower fees and making blockchain transactions more affordable for the masses.</P>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex $style={{
                        maxW: "45rem",
                        queries: {
                            680: {
                                order: '1'
                            }
                        }
                    }}>
                        <Image src={Constants.Imgs.BLOCKCHAIN2} />
                    </Flex>
                </Flex>
            </Flex>
            <Flex data-aos="fade-up" id="faq" as="section" $style={{
                w: "100%",
                maxW: "1440px",
                m: 'auto',
                mb: "12rem",
                p: '1rem'
            }}>
                <Flex $style={{
                    fDirection: "column",
                    vAlign: "center",
                    gap: "3rem"
                }}>
                    <Heading level={2}>FAQ</Heading>
                    <AccordionSection />
                </Flex>
            </Flex>
            <Flex data-aos="fade-up" id="team" as="section" $style={{
                w: "100%",
                maxW: "1440px",
                m: "auto",
                hAlign: "center",
                mb: "12rem",
                p: '1rem'
            }}>
                <Flex $style={{
                    fDirection: "column",
                    vAlign: "center",
                    gap: "3rem",
                    w: "100%"
                }}>
                    <Heading level={2}>Team Members</Heading>
                    <Grid $style={{
                        columns: "3",
                        gap: "5rem",
                        w: "100%",
                        $queries: {
                            768: {
                                columns: "2"
                            },
                            475: {
                                columns: "1"
                            }
                        }
                    }}>
                        <Flex $style={{
                            fDirection: "column",
                            vAlign: "center",
                            gap: "2rem"
                        }}>
                            <Image $style={{
                                radius: "100%",
                                maxW: "18rem"
                            }} src={Constants.Imgs.FRANCESCO} alt="rollup team" />
                            <Flex $style={{
                                fDirection: 'column',
                                vAlign: "center"
                            }}>
                                <Heading level={4}>Francesco Risitano</Heading>
                                <Span style={{ textAlign: 'center' }}>
                                    Founder / CTO
                                </Span>
                            </Flex>
                        </Flex>
                        <Flex $style={{
                            fDirection: "column",
                            vAlign: "center",
                            gap: "2rem"
                        }}>
                            <Image $style={{
                                radius: "100%",
                                maxW: "18rem"
                            }} src={Constants.Imgs.PEDRO} alt="rollup team" />
                            <Flex $style={{
                                fDirection: 'column',
                                vAlign: "center"
                            }}>
                                <Heading level={4}>Pedro Ortiz</Heading>
                                <Span style={{ textAlign: 'center' }}>
                                    CMO / CCO
                                </Span>
                            </Flex>
                        </Flex>
                        <Flex $style={{
                            fDirection: "column",
                            vAlign: "center",
                            gap: "2rem"
                        }}>
                            <Image $style={{
                                radius: "100%",
                                maxW: "18rem"
                            }} src={Constants.Imgs.TADASHI} alt="rollup team" />
                            <Flex $style={{
                                fDirection: 'column',
                                vAlign: "center"
                            }}>
                                <Heading level={4}>Tadashi Amano</Heading>
                                <Span>Lead Frontend Engineer</Span>
                            </Flex>
                        </Flex>
                        <Flex $style={{
                            fDirection: "column",
                            vAlign: "center",
                            gap: "2rem"
                        }}>
                            <Image $style={{
                                radius: "100%",
                                maxW: "18rem"
                            }} src={Constants.Imgs.ALI} alt="rollup team" />
                            <Flex $style={{
                                fDirection: 'column',
                                vAlign: "center"
                            }}>
                                <Heading level={4}>Ali Altindal</Heading>
                                <Span style={{ textAlign: 'center' }}>
                                    Chief Digital Engagement Architect<br />
                                </Span>
                            </Flex>
                        </Flex>
                        <Flex $style={{
                            fDirection: "column",
                            vAlign: "center",
                            gap: "2rem"
                        }}>
                            <Image $style={{
                                radius: "100%",
                                maxW: "18rem"
                            }} src={Constants.Imgs.FIZIKARUBI} alt="rollup team" />
                            <Flex $style={{
                                fDirection: 'column',
                                vAlign: "center"
                            }}>
                                <Heading level={4}>Fizikarubi</Heading>
                                <Span style={{ textAlign: 'center' }}>
                                    Rust Software Engineer<br />
                                </Span>
                            </Flex>
                        </Flex>
                    </Grid>
                </Flex>
            </Flex>
            <Footer />
        </Flex>
    )
}

export const Pulse = keyframes`
    0% { box-shadow: 0 0 0 0 #791b7a; }
`

export const Community = styled.div`
    display: flex;
    width: 5rem;
    height: 5rem;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    border-radius: 40rem;
    align-items: center;
    justify-content: center;
    z-index: 9;
    background:#161616;
    border:1px solid #791b7a;
    animation: ${Pulse} 1s infinite;
    box-shadow: 0 0 0 2em transparent;
`;