import { Container, Section, InnerSection, AccordionContainer, AccordionInner } from "components/basic/accordion";
import { useState, useRef } from "react";
import AccordionItems from "./accordion.items";

const sampleAccordionData = [
    {
        title: "What exactly is Fractal?",
        content: `Fractal is a comprehensive shared validity sequencer. Fractal offers dedicated chains with a unified experience. This means users benefit from a cohesive ecosystem of rollups that offers the UX of a single-chain, while enjoying the reliability and security of a decentralized network.`
    },
    {
        title: "How does Fractal achieve scalability?",
        content: `Fractal leverages horizontal scaling—commonly known as sharding. Each rollup operates as an individual shard with its distinct throughput capacity. By having a sharded achitecture, Fractal effectively mitigates congestion and contention issues prevalent in shared environments.`
    },
    {
        title: "How does Fractal ensure security?",
        content: `Fractal harnesses cutting-edge developments in zero-knowledge cryptography. By utilizing zero-knowledge proofs, Fractal inherits the industry-leading security of Ethereum.`
    },
    {
        title: "What sets Fractal apart from other platforms?",
        content: `Fractal distinguishes itself with a strong focus on simple rollup deployment and an uncompromising dedication to decentralization. This approach empowers developers to effortlessly deploy decentralized apps while preserving the foundational values of decentralization.`
    }
];

function AccordionSection() {

    const [currentAccordion, setCurrentAccordion] = useState(-1);
    const [bodyHeight, setBodyHeight] = useState(0);

    const refs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    return (
        <Container>
            <Section>
                <InnerSection>
                    <AccordionContainer>
                        <AccordionInner>
                            <AccordionItems
                                accordionContent={sampleAccordionData}
                                refs={refs}
                                currentAccordion={currentAccordion}
                                setCurrentAccordion={setCurrentAccordion}
                                setBodyHeight={setBodyHeight}
                                bodyHeight={bodyHeight}
                            />
                        </AccordionInner>
                    </AccordionContainer>
                </InnerSection>
            </Section>
        </Container>
    )
}

export default AccordionSection;