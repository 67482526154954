import React from "react";
import { InputContainer, InputContent, InputLabel, InputWrapper, StyledInput, StyledInputPropsType, StyledTextarea } from "./style";

type InputPropsType = {
    label?: string | React.ReactNode
    value?: string
    onChange?: any
    placeholder?: string
    helpSide?: any
    $style?: StyledInputPropsType
    type?:string
    disabled?: boolean
}

export const Input: React.FC<InputPropsType> = ({
    label,
    helpSide,
    type,
    $style,
    disabled,
    ...rest
}) => {
    return (
        <InputContainer>
            {label && (<InputLabel>{label}</InputLabel>)}
            <InputContent>
                <InputWrapper  {...$style}>
                    <StyledInput disabled={disabled} type={type} {...rest} />
                    {helpSide}
                </InputWrapper>
            </InputContent>
        </InputContainer>
    )
}

type TextareaPropsType = InputPropsType & {}

export const Textarea: React.FC<TextareaPropsType> = ({
    label,
    helpSide,
    $style,
    ...rest
}) => {
    return (
        <InputContainer>
            {label && (<InputLabel>{label}</InputLabel>)}
            <InputContent>
                <InputWrapper  {...$style}>
                    <StyledTextarea as="textarea" rows={5} {...rest} />
                    {helpSide}
                </InputWrapper>
            </InputContent>
        </InputContainer>
    )
}
