import configs from "configs";

const doSubscribe = async (email: string) => {
    try {
        const res = await fetch(configs.links.rollupManger + '/subscribe/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email
            })
        });


        if (res.ok) {
            return {
                message: 'Done Subscribe'
            };
        } else {
            const convertedRes = await res.json();

            return {
                message: 'Error Occurred',
                detail: convertedRes.detail || [],
                error: true
            };
        }
    } catch (err: any) {
        console.log('Error occurred when do signin:', err);
        return {
            message: err.response
        };
    }
}

export default {
    doSubscribe
}
