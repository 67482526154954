import configs from "configs";
import Button from "components/basic/button";
import Flex from "components/basic/flex";
import Image from "components/basic/image";
import { Span } from "components/basic/text";
import Constants from "constant";
import Link from "components/basic/link";

export default function Header() {
    return (
        <Flex as="header" $style={{
        }}>
            <Flex $style={{
                vAlign: "center",
                hAlign: "space-between",
                w: "100%",
                maxW: "1440px",
                m: "auto",
                p: "1rem"
            }}>
                <Flex $style={{
                    maxW: "12rem"
                }}>
                    <Image src={Constants.Imgs.LOGO} />
                </Flex>
                <Flex as="ul" $style={{
                    gap: "3rem",
                    queries: {
                        1024: {
                            display: "none"
                        }
                    }
                }}>
                    <Flex as="li" $style={{
                        cursor: 'pointer'
                    }}>
                        <a href="#home">
                            <Span $style={{
                                size: "1.1rem"
                            }}>
                                Home
                            </Span>
                        </a>
                    </Flex>
                    <Flex as="li" $style={{
                        cursor: "pointer"
                    }}>
                        <a href="#about">
                            <Span $style={{
                                size: "1.1rem"
                            }}>
                                About us
                            </Span>
                        </a>
                    </Flex>
                    <Flex as="li" $style={{
                        cursor: "pointer"
                    }}>
                        <a href="#team">
                            <Span $style={{
                                size: "1.1rem"

                            }}>
                                Team
                            </Span>
                        </a>
                    </Flex>
                    <Flex as="li" $style={{
                        cursor: "pointer"
                    }}>
                        <a href="#faq">
                            <Span $style={{
                                size: "1.1rem"

                            }}>
                                FAQ
                            </Span>
                        </a>
                    </Flex>
                    <Flex as="li" $style={{
                        cursor: "pointer"
                    }}>
                        <a href={configs.links.community}>
                            <Span $style={{
                                size: "1.1rem"
                            }}>
                                Community
                            </Span>
                        </a>
                    </Flex>
                    <Flex as="li" $style={{
                        cursor: "pointer"
                    }}>
                        <a href={configs.links.docs}>
                            <Span $style={{
                                size: "1.1rem"
                            }}>
                                Docs
                            </Span>
                        </a>
                    </Flex>
                </Flex>
                <Link to={configs.links.portal}>
                    <Button
                        $style={{
                            bg: "bg-gradient",
                            kind: "radius",
                            hover: "linear-gradient(to right, #1d60ac, #791b7a)"
                        }}
                    >
                        Enter Portal
                    </Button>
                </Link>
            </Flex>
        </Flex>
    )
}