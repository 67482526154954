import { useState } from "react";
import LandingPageUI from "./ui";
import apis from "apis";
import { openNotification } from "utils/toast.util";

export default function LandingPage() {

    const [email, setEmail] = useState('');

    const handleEmail = (e: any) => {
        setEmail(e.target.value);
    }

    const handleSubmit = async () => {
        const { message, detail } = await apis.UserAPI.doSubscribe(email);

        if (!!detail) {
            openNotification(detail[0].msg || detail);
        } else {
            openNotification(message);
        }
    }

    return (
        <LandingPageUI
            handleEmail={handleEmail}
            handleSubmit={handleSubmit}
        />
    )
}

