import './App.css';
import GlobalStyle from 'style/globalStyle';
import { RouterProvider } from 'react-router-dom';
import mainRouter from 'routes';
import AOS from 'aos';
import { useEffect } from 'react';

import 'aos/dist/aos.css';

function App() {

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 300,
    });
  }, [])

  return (
    <>
      <GlobalStyle />
      <RouterProvider router={mainRouter} />
    </>
  );
}

export default App;
