import apis from "apis";
import Button from "components/basic/button";
import Flex from "components/basic/flex";
import Heading from "components/basic/heading";
import Icon from "components/basic/icon";
import Image from "components/basic/image";
import { Input } from "components/basic/input";
import { P, Span } from "components/basic/text";
import Constants from "constant";
import { useState } from "react";
import { openNotification } from "utils/toast.util";

export default function Footer() {

    const [email, setEmail] = useState('');

    const handleEmail = (e: any) => {
        setEmail(e.target.value);
    }

    const handleSubmit = async () => {
        const { message, detail } = await apis.UserAPI.doSubscribe(email);

        if (!!detail) {
            openNotification(detail[0].msg || detail);
        } else {
            openNotification(message);
        }
    }

    return (
        <Flex as="footer" $style={{
        }}>
            <Flex $style={{
                w: "100%",
                maxW: "1440px",
                m: "auto",
                p: "1rem",
                hAlign: "space-between",
                gap: "1rem",
                fWrap:'wrap'
            }}>
                <Flex $style={{
                    fDirection: "column",
                    vAlign: "flex-start",
                    gap: "1rem",
                    maxW: "20rem"
                }}>
                    <Flex $style={{
                        maxW: "10rem"
                    }}>
                        <Image src={Constants.Imgs.LOGO} />
                    </Flex>
                    <Flex $style={{
                        gap: "1rem"
                    }}>
                        <Flex $style={{
                            border: "1px solid #942496",
                            radius: "50rem",
                            w:"2.5rem",
                            h:"2.5rem",
                            hAlign:"center",
                            vAlign:"center"
                        }}>
                            <Icon icon={'twitter'} />
                        </Flex>
                        <Flex $style={{
                            border: "1px solid #942496",
                            radius: "50rem",
                            w:"2.5rem",
                            h:"2.5rem",
                            hAlign:"center",
                            vAlign:"center"
                        }}>
                            <Icon icon={'telegram'} />
                        </Flex>
                        <Flex $style={{
                            border: "1px solid #942496",
                            radius: "50rem",
                            w:"2.5rem",
                            h:"2.5rem",
                            hAlign:"center",
                            vAlign:"center"
                        }}>
                            <Icon icon={'discord'} />
                        </Flex>
                    </Flex>
                </Flex>

                {/* <Flex as="ul" $style={{
                    fDirection: "column",
                    gap: "1rem"
                }}>
                    <Flex as="li">
                        <Heading level={4}>USEFUL LINKS</Heading>
                    </Flex>
                    <Flex as="li">
                        <Span>Contact us</Span>
                    </Flex>
                    <Flex as="li">
                        <Span>How it works</Span>
                    </Flex>
                    <Flex as="li">
                        <Span>Create</Span>
                    </Flex>
                    <Flex as="li">
                        <Span>Explore</Span>
                    </Flex>
                    <Flex as="li">
                        <Span>Terms & Services</Span>
                    </Flex>
                </Flex>
                <Flex as="ul" $style={{
                    fDirection: "column",
                    gap: "1rem"
                }}>
                    <Flex as="li">
                        <Heading level={4}>COMMUNITY</Heading>
                    </Flex>
                    <Flex as="li">
                        <Span>Help Center</Span>
                    </Flex>
                    <Flex as="li">
                        <Span>Partners</Span>
                    </Flex>
                    <Flex as="li">
                        <Span>Suggestions</Span>
                    </Flex>
                    <Flex as="li">
                        <Span>Blog</Span>
                    </Flex>
                    <Flex as="li">
                        <Span>NewsLetters</Span>
                    </Flex>
                </Flex> */}
                
                <Flex $style={{
                    fDirection: "column",
                    maxW: "25rem",
                    gap: "1rem"
                }}>
                    <Heading level={4} >
                        Subscribe
                    </Heading>
                    <P>
                        Stay informed with updates on our test network, and discover exclusive opportunities to get involved.
                    </P>
                    <Flex $style={{
                        gap: "1rem"
                    }}>
                        <Input onChange={(e: any) => handleEmail(e)} placeholder="Enter your email" />
                        <Button onClick={handleSubmit} $style={{
                            bg: "bg-gradient",
                            kind: "radius",
                            hover:"linear-gradient(to right, #1d60ac, #791b7a)"
                        }}>
                            Subscribe
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}