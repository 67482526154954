const appPath = {
    PUBLIC_PREFIX: '/',
    APP_PREFIX: '/app/',
    NODES_PREFIX: 'nodes/',
    ROLLUP_PREFIX: 'rollup/',
    PROFILE_PREFIX: 'profile',
    CREATE_PREFIX: 'create',
    SETTING_PREFIX: 'setting'
}

export default appPath;