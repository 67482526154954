import BG from 'assets/imgs/landing-bg.png';
import BLOCKCHAIN1 from 'assets/imgs/blockchain-1.png';
import BLOCKCHAIN2 from 'assets/imgs/blockchain-2.png';
import BLOCKCHAIN3 from 'assets/imgs/blockchain-3.png';
import USEROPTIONS from 'assets/imgs/user-options.png';

import LOGO from 'assets/imgs/logo.png';

import GRADIENT_1 from 'assets/imgs/gradient-1.png';
import GRADIENT_2 from 'assets/imgs/gradient-2.png';
import GRADIENT_3 from 'assets/imgs/gradient-3.png';
import GRADIENT_4 from 'assets/imgs/gradient-4.png';
import LOOPER from 'assets/imgs/looper.png';

import FRANCESCO from 'assets/imgs/Francesco.jpg';
import ALI from 'assets/imgs/ali.jpg';
import FIZIKARUBI from 'assets/imgs/fizikarubi.jpg';
import PEDRO from 'assets/imgs/Pedro.jpg';
import TADASHI from 'assets/imgs/tadashi.jpg';

const Imgs = {
    BG,
    BLOCKCHAIN1,
    BLOCKCHAIN2,
    USEROPTIONS,
    FRANCESCO,
    FIZIKARUBI,
    ALI,
    PEDRO,
    TADASHI,
    LOGO,
    GRADIENT_1,
    GRADIENT_2,
    GRADIENT_3,
    GRADIENT_4,
    LOOPER,
    BLOCKCHAIN3
}

export default Imgs;